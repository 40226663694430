const appConfig = {
    apiPrefix: 'https://validades.aws.inf.br/validades',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/home',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
